.crud > .tab-addition,
.tab-addition.is-small {
    flex: 1;
    display: flex;
    flex-direction: column;

    background-color: transparent;
    box-shadow: 0 0 0 rgba(white, 0);
    border: 0;
    overflow-x: hidden;

    > .tab-addition__header {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        overflow-x: auto;
        min-height: 1px;

        &::-webkit-scrollbar {
            display: none;
        }

        > header {
            display: flex;

            button {
                background: 0;
                flex-grow: 0;
                min-width: 80px;
                padding-left: 10px;
                padding-right: 10px;
                border: 1px solid transparent;
                border-top: 0;
                white-space: nowrap;

                &:last-child {
                    border-right: 1px solid transparent;
                }

                &.is-active {
                    background: white;
                    border-left-color: $gray-light;
                    border-right-color: $gray-light;
                    border-bottom: 1px solid white;
                }
            }
        }
    }

    > main,
    > .main {
        flex: 1;
        margin-top: -1px;
        background: white;
        border: 1px solid $gray-light;
        box-shadow: $box_shadow;

        .columns{

            .has-seperating-borders {
                .column {
                    &:not(:first-child) {
                        border-left: 1px solid $border-color;
                    }
                }
            }

            .column {
                display: flex;
                flex-direction: column;

                .is-headline:not(.row) {
                    margin-bottom: 0;
                    border-bottom: 1px solid $border-color;
                    padding-bottom: 5px;
                    margin-top: 5px;
                }

                .bp-panel {

                    flex: 1;
                    min-height: 150px;

                    &.is-settings {
                        min-height: 130px;
                    }

                    &.has-no-title {
                        padding: 15px 0 15px;
                    }

                    &.is-add {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                      transition: .25s background;

                        &:hover {
                            background: lighten($background-color, 5%);
                        }

                        > .material-icons {
                            font-size: 36px;
                        }
                    }

                    .panel-content {

                        p {
                            margin-bottom: 0;
                        }

                        .buttons {
                            margin-top: -5px;

                            .button {
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.crud {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    aside {
        width: 420px;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 30px;

        &.is-right {
            margin-right: 0;
            margin-left: 30px
        }
        margin-bottom: 0;

        &.is-smaller {
            width: 320px;
        }

        .v-popover {
            &:not(.userPopover) {
                position: absolute;
            }

            bottom: 7px;
            right: 7px;
        }

        .mb-0 { margin-bottom: 0; }
        .mb { margin-bottom: 15px; }
        .mb-2x { margin-bottom: 30px }

        .rating {
            &__circle {
                margin-top: 5px;
                margin-bottom: 0;
            }
        }

        ul {
            li {
                &.is-url {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                > i {
                    margin-right: .75rem;
                    width: 32px;
                    flex-shrink: 0;
                    text-align: center;
                    color: $text-color;
                }

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                a {
                    color: $link-color;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .tag {
                    padding: 1px 10px;
                    line-height: normal;
                    border-radius: 0;
                    height: auto;
                    margin-right: 5px;
                    margin-bottom: 0;
                    background: $accent;

                    a, i {
                        color: inherit;

                        &.fa-external-link {
                            margin-left: 10px;
                        }
                    }
                }

                .badges {
                    display: inline-block;

                    > * {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    > main {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 80vh;
        margin-bottom: 0;

        border: 0;
        overflow-x: hidden;
    }

    .day {
        border-left:1px solid $gray-light;
        border-right:1px solid $gray-light;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05),
            0 1px 2px rgba(0, 0, 0, 0.1);

        margin-bottom: 30px;
    }
}

@media all and (max-width: 1919px) {
    .crud > aside {
        width: 420px;
        position: absolute;
        top: 15px;
        left: 10px;
        z-index: 2;
        transition: transform 0.2s ease-out, opacity 0.2s ease-out;
        transform: translateX(-420px);
        opacity: 0;
        display: none;
        padding: 15px 20px;

        & + .overlay {
            background-color: rgba(0, 0, 0, 0.2);
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: opacity 0.2s ease-out;
            opacity: 0;
            display: none;
        }

        &.is-active, &.is-active + .overlay { display: block; }
        &.is-visible, &.is-visible + .overlay {
            transform: translateX(0);
            opacity: 1;
        }

        &.is-active,
        &.is-visible {
            z-index: 2;

            & + .overlay {
                z-index: 1;
            }
        }
    }
}
