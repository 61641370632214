.todo {
    height: 68px;
    background-color: $background-color;
    margin-bottom: 20px;
    padding: 7px 10px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    border-top: 2px solid $accent;

    &__name {
        flex: 1;
        font-weight: 600;
        @include ellipsis();
        margin-right: 15px;

        .material-icons {
            vertical-align: -2px;
            font-size: 14px;
            margin-left: 4px;
        }

        .isExternal {
           color: $red-light;
        }

    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    .user {
        height: 22px;
        width: 22px;
    }

    .bp-deadline.is-editable {
        .input {
            width: 90px;
            height: 21px;
            padding-left: 20px;
            line-height:22px;
        }

        &::before {
            left: 6px;
        }
    }


    &__info {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        flex: 1;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background-image: linear-gradient(to right, transparent, $background-color);
            width: 30px;
        }
    }

    .tag {
        height: 22px;
        background-color: $accent;
        margin-right: 10px;
        padding: 2px 5px;
        color: white;

        &:not(.is-white) {
            height: 20px;
        }

        i {
            margin-right: 3px;
            color: white;
            &.no-margin { margin-right: 0; }
        }
    }

    .deadline-date {
        margin-top:0;
        line-height: normal;
        display: inline-flex;
        align-items: center;

        i.material-icons {
            margin-right: 3px;
        }
    }
}

// User dashboard
.list {
    .todo {
        border-bottom: 1px solid $gray-light;
    }
}

// Trip dashboard - orders/1 - aside
.modal--todos {
    .buttons.has-addons {
        padding-top: 0;
    }

    .todos {
        padding-bottom: 30px;

        &__category {
            padding: 5px 15px;
            @extend h2;
            margin-bottom: 15px;
            box-shadow: 0 0 3px rgba(#000, .2);
        }
    }

    .todo {
        background: $background-color;
        border-radius: $border-radius;
        border-top: 2px solid $accent;
        box-shadow: 0 1px 3px rgba(#000, .2);
        margin-bottom: 15px;
    }
}

.todo__popover {
    hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .buttons {
        margin-top: 5px;
    }
}

.favicon {
    flex-shrink: 0;
    flex-grow: 0;
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-right: 7px;
    transition: background 0.2s ease-out;
}

// User image
.user {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    transition: background 0.2s ease-out;
    background: transparent;

    &__avatar {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 0.2s ease-out;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &.is-text {
            background: $blue-active;
            justify-content: center;
            display: flex;
            color:white;
            align-items: center
        }
    }

    i {
        color: white;
        opacity: 0;
        transition: opacity 0.2s ease-out;
    }

    &.is-editable {
        &:hover {
            background: #000;

            .user__avatar {
                opacity: 0.5;
            }

            i {
                opacity: 1;
            }
        }
    }

    &.is-small {
        width: 28px;
        height: 28px;
    }
}

.users {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;


    .user {
        border: 2px solid $gray-lightest;
        margin-left: -24px;

        &.is-small {
            margin-left: -14px;
        }

        &.is-favicon {
            border: 0;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}
