aside {
    transition: width 0.4s ease 0s;
}

#app.is-right-aside-hidden > .wrapper {
    aside {
        transition: width 0.4s ease 0s;

        width: 30px;
        border-left: 1px solid $blue-lighter;
        border-top: 1px solid $blue-lighter;
        border-bottom: 1px solid $blue-lighter;
        padding: 0;
        span {
            color: $blue-lighter;
        }

        i {
            color: $blue-lighter;
            font-size: 24px;
        }
        &:hover {
            border-left: 1px solid $blue-light;
            border-top: 1px solid $blue-light;
            border-bottom: 1px solid $blue-light;
            i {
                color: $blue-light
            }
        }

    }
}

#app.is-left-aside-hidden > .wrapper {
    aside {
        transition: width 0.4s ease 0s;

        width: 30px;
        border-right: 1px solid $blue-lighter;
        border-top: 1px solid $blue-lighter;
        border-bottom: 1px solid $blue-lighter;
        padding: 0;

        i {
            color: $blue-lighter;
            font-size: 24px;
        }
        &:hover {
            border-right: 1px solid $blue-light;
            border-top: 1px solid $blue-light;
            border-bottom: 1px solid $blue-light;
            i {
                color: $blue-light
            }
        }

    }
}

.emails-list {
    min-width: 378px;
    min-height: 1125px;
    display: flex;
    flex-shrink: 0;
    margin-right: 15px;

    a {
        height: 100%;
        color: $link-color;
        display: inline-block;
        &:hover { text-decoration: underline; }
    }


    .new-email-button {
        min-height: 60px;
        padding: 6px 10px;
        border-bottom: 1px solid $gray;
        align-items: center;
        display: flex;
        button { width: 100%; };
        gap: 10px;
    }


    .emails-list_categories, .emails-list_items {
        @include box-with-top-color;
        min-height: 100px;
        width: 50%;

        .organisation-select {
            min-height: 40px;
            padding: 4px 10px;
            border-bottom: 1px solid $gray;
            display: flex
        }

        header {
            min-height: 40px;
            padding: 6px 10px;
            border-bottom: 1px solid $gray;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div * {
                display: inline-block
            }

            h2 {
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
    }

    .emails-list_categories {
        width: 378px;

        .content {
            height: 1005px;
            overflow-y: scroll;
            padding-top: 10px;
        }

        .loading-data {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
                margin-bottom: 0
            }
        }

        .content button {
            min-height: 40px;
            padding: 0 10px;
            font-weight: 700;
            display: flex;

            i {
                font-size: 23px;
                margin-right: 10px;
                transition: color 0.2s ease-out, margin-right 0.2s ease-out;
                &.is-down { transform: rotate(90deg); }
            }
            &[class^="is-"]:not([class="is-0"]) > i {
                font-size: 23px;
            }
            span {
                padding-left: 10px;
                color: $accent;
            }

            &.is-selected {
                i { color: $green; }
            }
            &:hover i { margin-right: 15px; }
        }

        .subfolders {
            &.are-closed { display: none; }
            button { font-weight: 600; }
        }

        .is-0 > button {
            position: relative;
            padding-left: 33px;
            & > .is-arrow {
                position: absolute;
                //top: 9px;
                left: 6px;
            }
        }
        .is-1 { padding-left: 25px; }
        .is-2 { padding-left: 25px; }
        .is-3 { padding-left: 25px; }
        .is-4 { padding-left: 25px; }
    }


    .emails-list_items {
        width: 467px;

        &.is-concept {
            width: 420px;
        }

        margin-left: 15px;
        flex-shrink: 0;
        flex-direction: column;
        display: flex;

        header span {
            width: 25px;
            height: 25px;
            margin-right: 17px;
            float: right;
            border-radius: 50%;
            border: 1px solid $border-color;
            text-align: center;
            font-weight: 600;
            line-height: 23px;
            display: inline-block;
        }

        .content { overflow-y: scroll; }
    }

    .emails-list-filters {
        height: 60px;
        padding: 6px 10px;
        border-bottom: 1px solid $gray;
        align-items: center;
        display: flex;
        i {
            margin-right: 10px;
            font-size: 16px;
        }
    }

    .buttons-wrapper {
        position: relative;
        padding: 10px;
        flex-wrap: wrap;
        display: flex;

      /*  &:after {
            content: ' ';
            width: 100%;
            height: 1px;
            background: $border-color;
            position: absolute;
            top: 50px;
            left: 0;
        }*/

        button {
            flex-grow: 1;
            &:first-child { border-radius: $border-radius 0 0 $border-radius; margin-right: -1px; }
            &:last-child  { border-radius: 0 $border-radius $border-radius 0; }
        }

        .input {
            width: 100%;
            margin-top: 20px;
        }
    }


    .accordion {
        position: relative;
        margin-bottom: 40px;
        border-top: 0 none;
        &:first-child h4 { border-radius: $border-radius $border-radius 0 0; }
        &:last-child { margin-bottom: 0; }
    }
    .accordion-header {
        min-height: 40px;
        position: relative;
        padding: 0;
        &:after { content: none; }
        z-index: 1;
    }
    .accordion-content {
        & > div {
            max-height: 219px;
            position: relative;
            z-index: 1;
            overflow-y: scroll;
        }
        .dragArea {
            min-height: 50px;
            .loading-data { background-color: white; }
        }
    }
    .accordion.is-open:last-child .accordion-content > div { max-height: 73px; }
    .accordion.is-open .accordion-content:after {
        content: 'Keine Emails vorhanden';
        width: 100%;
        position: absolute;
        bottom: 3px;
        left: 0;
        line-height: 57px;
        font-weight: 600;
        text-align: center;
    }


    h4 {
        min-height: 40px;
        background-color: $accent;
        margin-bottom: 0;
        padding: 0 10px;
        color: white;
        font-weight: 600;
        line-height: 40px;
        cursor: pointer;

        &.is-inbox, &.is-sent { background-color: $gray-darker; }
        &.is-important        { background-color: $pink_dark; }
        &.is-archive          { background-color: $gray_light; color: $text-color; }

        .nr-of-emails {
            width: 25px;
            height: 25px;
            background-color: white;
            float: right;
            margin-top: 8px;
            border: 1px solid $border-color;
            text-align: center;
            border-radius: 50%;
            font-size: $font-size;
            line-height: 24px;
            display: inline-block;
        }
    }


    .email {
        min-height: 60px;
        background-color: white;
        padding-left: 12px;
        border-bottom: 1px solid $border-color;
        cursor: pointer;
        user-select: none;
        display: flex;
        position: relative;

        &.is-outgoing { background-color: darken($background-color, 1%)}
        &:hover       { background-color: $background-color; }
        &.is-selected { background-color: $light-orange; }


        .column:nth-child(1) { flex: none; flex-basis:  18px; padding-right: 0px; padding-left: 0px;
            i {
                font-size: 18px;
                margin-left: 10px;
            }
            .incoming {
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
            }

            > div {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }

        .column:nth-child(2) {
            width: 380px;
            > div {
                display: flex;
                align-items: center;
            }
        }


        .title {
            font-size: $font-size;
            width: 240px
        }

        .date {
            font-size: 10px;
        }

        .contact {
            color: $link-color;
        }

        .user-image {
            justify-content: center;
            display: flex;
            flex: 1;
            align-items: center;
        }

        .title, .description, .contact {
            @include ellipsis();
            margin-bottom: 0px;
            line-height: 1.7;
        }
        .buttons {
            padding-top: 5px;
            &.email-view {
                max-width: 300px;
                margin-bottom: 0;
                height: 23px;

            }
        }

        .attachments {
            position: absolute;
            bottom: 13px;
            right: 20px;
        }


        &.sortable-chosen.sortable-ghost { background-color: $gray; }
    }

    &.is-simple {
        flex-direction: column;
        display: flex;

        .list {
            position: relative;
            flex: 1;
            .email:first-child { border-radius: $border-radius $border-radius 0 0; }

            .list-scrollable {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                max-height: 100%;
                overflow-y: auto;
            }
        }

        .emails-list_items {
            margin-left: 0;
            border-bottom: 1px solid $border-color;
        }
        .is-search{
            width: auto;
            margin: 15px;
        }
    }


    // Overview in user-dashboard
    &.is-list-only {
        background-color: $background-color;
        padding-bottom: 0;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        overflow-y: visible;
        flex-direction: column;
        display: flex;

        & > .title {
            min-height: 40px;
            margin-bottom: 0;
            padding: 0 10px;
            color: $text-color;
            font-size: $font-size;
            line-height: 40px;
            justify-content: space-between;
            align-items: center;
            display: flex;
            > div {
                align-items: center;
                display: flex;
                a, button { margin-left: 10px; }
            }
            .is-gray {
                // Overriding
                background-color: white;
                margin-top: 5px;
            }
        }

        & > div:nth-child(2) {
            padding: 5px 10px 6px;
            flex: none;
            display: flex;
            & > * { margin: 0 5px; }
            & > *:first-child { margin-left: 0; }
            & > *:last-child { margin-right: 0; }
        }

        & > div:nth-child(4) { overflow-y: scroll; }

        .email {
            padding-right: 16px;
            .column:nth-child(2) { width: 244px; }
        }
        .multiselect { line-height: 18px; }
    }


    &.is-sent {
        overflow: visible;
        .accordion.is-open:last-child .accordion-content > div, .accordion-content > div { max-height: 438px; }
    }
}
