.is-calculations,
.is-offer {
    min-height: 300px;
    position: relative;

    .box {
        padding: 10px;

        .panel {
            &-heading {
                justify-content: normal;

                .mr { margin-right: 10px; }
            }

            &:not(.is-padded) {
                .panel-block {
                    padding: 0;
                }
            }
        }
    }

    .notification {
        font-size: $font-size;
        background: 0 !important;
        text-align: center;
        font-weight: 600;
    }

    .list {
        font-size: $font-size;

        &__item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;

            border-bottom: 1px solid $border-color;

            i.material-icons { font-size: 16px; }
            i.fa { font-size: 14px }

            .mx {
                margin-right: 5px;
                margin-left: 5px;
            }
            .mr { margin-right: 5px; }
            .ml { margin-left: 5px; }

            &__details {
                min-height: 30px;
                padding: 1px 10px;
                display: flex;
                align-items: center;

                &.is-icons {
                    width: 65px;
                    justify-content: center;
                    padding-left: 0;
                    padding-right: 0;
                }

                &.is-date {
                    width: 160px;

                    &.plus-nights:not(.plus-name) {
                        width: 160px + 50px;
                    }

                    &.plus-name:not(.plus-nights) {
                        width: 160px + 280px;
                        flex:1;
                    }

                    &.plus-name.plus-nights {
                        width: 160px + 280px + 50px;
                        flex:1;
                    }
                }

                &.is-small-date {
                    width: 100px;
                }

                &.is-nights {
                    width: 50px;
                    justify-content: center;
                }

                &.is-name {
                    width: 280px;
                    flex: 1;
                }

                &.is-double,
                &.is-single,
                &.is-profit,
                &.is-margin {
                    width: 140px;
                }

                &.is-profit-margin {
                    width: 280px;
                }

                &.is-buttons {
                    padding-left: 0;
                    padding-right: 20px;
                    width: 60px;
                    justify-content: flex-end;

                    i {
                        cursor: pointer;
                        transition: .25s transform;

                        &.is-rotated {
                            transform: rotate(180deg);
                        }
                    }

                    button {
                        height: 28px;
                        padding-bottom: 2px;
                    }
                    .btn-expand {
                        position: absolute;
                        right: 13px;
                    }
                }
            }

            &.is-head {
                font-weight: 600;

                .list__item__details {
                    padding-top: 7px;
                    padding-bottom: 7px;
                    white-space: nowrap;

                    i.material-icons, i.fa {
                        width: 20px;
                    }

                    &.is-double,
                    &.is-single,
                    &.is-margin,
                    &.is-profit,
                    &.is-profit-margin {
                        i.material-icons, i.fa {
                            width: 25px;
                            text-align: center;
                        }
                    }

                    &.is-nights, &.is-icons {
                        i.material-icons, i.fa {
                            width: auto;
                        }
                    }
                }
            }

            &.is-foot {
                .list__item__details {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-weight: 600;
                }
            }

            &.is-collapse {
                .is-date {
                    font-weight: 600;
                }

                .input.is-tiny {
                    text-align: center;
                }
            }
        }

        .collapse,
        .is-darker {
            background: $gray-lighter;
        }

        .collapse.is-collapsed {
            overflow: hidden;
        }
    }

    [data-prefix] {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &::before {
            content: attr(data-prefix);
            width: 25px;
            text-align: center;
        }
    }

    span[data-prefix] {
        padding-right: 5px;
    }

    .is-overlay {
        background: rgba($background-color, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
}

.is-calculations {
    .contingent {
        display: flex;
        align-items: center;
        font-size: $font-size;
        min-height: 30px;
        padding: 5px 10px;

        .input {
            text-align: center;
            width: 40px;
        }

        span {
            padding-left: 10px;
            padding-right: 10px;
        }

        &__item {
            margin-right: 20px;
            display: flex;
            align-items: center;
        }
    }

    .freeplaces {
        font-size: $font-size;

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            min-height: 30px;
            padding: 5px 19.5px 5px 10px;

            > div {
                display: flex;
                align-items: center;
            }
        }

        span { white-space: nowrap; }

        .input {
            text-align: center;
            width: 40px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .select {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}

.is-offer {
    .table--message {
        width: 100%;

        th { width: 30%; }
        td { width: 70%; }

        th, td { padding: 5px 10px; }

        .label {
            font-weight: 600;
            line-height: 26px;
        }
    }

    > .box { margin-bottom: 1.5rem; }
}

.attached-orders {
    padding: 10px;
    .columns { flex-wrap: wrap; }
    .has-multiselect {
        width: 541px;
        flex: none;
    }
}

.attached-order {
    border-radius: $border-radius;
    background: $gray-lighter;
    box-shadow: 0 0 4px rgba(#000, .3);
    height: 30px;
    padding: 3px 10px;
    font-size: $font-size;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .multiselect { margin-right: 10px; }

    .order__details {
        display: flex;
        flex: 1;

        strong {
            margin-right: 10px;
        }

        span, strong {
            display: flex;
            align-items: center;

            i { margin-right: 5px; }
        }
    }

    .order__buttons {
        display: flex;
        align-items: center;
        margin-left: 10px;

        i {
            cursor: pointer;

            &.fa { font-size: 14px; }
            &:not(:last-child) { margin-right: 5px; }
        }
    }

    &.is-green {
        background: $ocean-green;
        color: white;
        * { color: inherit !important; }
    }
}

.conditions {
    position: relative;

    &__category {
        border-radius: $border-radius;
        background: $gray-lighter;
        box-shadow: 0 0 4px rgba(#000, .3);
        height: 30px;
        padding: 3px 10px;
        font-size: $font-size;
        font-weight: 600;

        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.is-editable {
            transition: .2s background;
            &:hover {
                background: $background-color;
            }
        }
    }

    .card {
        min-height: 0;
        background: white;
        border: 1px solid $gray;
        margin-bottom: 10px;
        box-shadow: 0 0 0 rgba(white, 0);

        .mx {
            margin-left: 5px;
            margin-right: 5px;
        }

        .mr {
            margin-right: 5px;
        }

        .is-nowrap {
            white-space: nowrap;
        }

        .columns {
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .column {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }

        .label {
            font-weight: 600;
        }

        .freeplace{
            display: flex;
            flex-direction: row;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

.bulletpoints {
    position: relative;
}

.bulletpoint {
    min-height: 200px;
    display: flex;
    flex-direction: column;

    &__name {
        border-radius: $border-radius;
        background: $gray-lighter;
        border: 1px solid $gray-light;

        height: 30px;
        padding: 3px 10px;
        font-size: $font-size;
        font-weight: 600;

        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .button.is-fullwidth {
        flex: 1;
    }

    .level {
        margin-bottom: 10px;

        .select {
            margin-right: 10px;

            select {
                height: 30px;
            }
        }
    }
}

@media screen and (max-width: 1800px) {
    .is-calculations {
        > .columns {
            flex-direction: column;

            > .column {
                flex: none;
                max-width: 1250px;
            }
        }
    }
}

@media screen and (max-width: 1720px) {
    .is-offer {
        > .box > .columns {
            flex-direction: column;

            > .column {
                flex: none;
                width: 100% !important;
            }
        }
    }
}



// Redesign
.calculation {
    > .main-panel {
        background :0;
        margin: 0;
        padding: 0;
        border-radius: 0;
        box-shadow: 0 0 0 rgba(#000, 0);
    }

    .loaded-search {
        margin-bottom: 15px;
    }

    .redesign-table {
        .is-additional {
            .row {
                .cell {
                    color: $additonal-color
                }
            }
        }

        .row {
            &.is-header {
                justify-content: space-between
            }

            &.is-head,
            &.is-headline {
                height: 31px;
            }

            &.is-headline,
            &.darken {
                font-size: 11px;
                background: $rowHeading;
            }

            &.isItemPriceRow {
                .cell {
                    opacity: 60%
                }
            }

            &:not(.is-head) {
                .cell.is-price-cell {
                    border-left: 1px solid $gray-light;

                    &.has-last-right-border {
                        &:last-child {
                            border-right: 1px solid $gray-light;
                        }
                    }

                    &.is-margin-cell {
                        border-right: 1px solid $gray-light;
                    }
                }
                .cell.is-currency-cell {
                    border-left: 1px solid $gray-light;
                }
            }
        }

        .cell {
            &.is-centered {
                justify-content: center;
            }

            &.is-price-cell {
                width: 130px;

                &.is-half {
                    width: 65px;
                }

                &.is-small {
                    width: 70px;

                    //justify-content: center;
                }

                .is-currency {
                    padding-left:10px
                }

                display: flex;
                flex-direction: row;
                align-items: center;
                flex-grow: 0;
                flex-shrink: 0;

                padding-left: 0;
                padding-right: 0;
                height: 30px;

                .margin-tax {
                    padding-left: 10px;
                    padding-right: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .v-popover {
                        height: auto;

                        .trigger {
                            height: auto;
                        }
                    }
                }

                .empty {
                    padding-right: 10px;
                    padding-left: 10px;
                    text-align: right;
                    display: block;
                    width: 100%;
                }

                .input-price {
                    border:0;

                    .is-blue {
                        color: $currency-info-color;
                    }

                    &.is-read-only {
                        background: 0;
                    }

                    &.is-highlight {
                        background: rgba($green, .2);
                    }
                }

                .v-popover {
                    height: 30px;
                    flex-shrink: 0;

                    .trigger {
                        height: 30px;
                    }

                    & + .link {
                        margin-left: 0;
                    }
                }
            }

            &.is-name-cell {
                padding-right: 0;

                > .input {
                    border-left: 1px solid $gray-light;
                    border-right: 0;
                }

                &.has-provider > .input {
                    border-right: 1px solid $gray-light;
                }
            }

            &.is-provider-cell {
                width: 180px;
                flex-grow: 0;
                flex-shrink: 0;
            }

            &.is-date-cell {
                max-width: 140px;
                overflow: visible !important;
                text-overflow: clip !important;
            }

            &.is-icon-cell {
                overflow: visible !important;
                text-overflow: clip !important;
                width: 50px;

                &.is-edit-icon {
                    width: 25px
                }

                .icon {
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    color: #fff;
                    background: $blue-dark;
                    text-align: center;
                    line-height: 22px;
                    font-size: 11px;

                    i.material-icons {
                        font-size: 12px;
                        color: inherit;
                    }
                }
            }
        }
    }

    .circle {
        width: 10px;
        height: 10px;
        border:1px solid $gray-light;
        border-radius: 50%;
        display: block;
        margin: 10px;
        flex-shrink: 0;
        flex-grow: 0;

        &.is-orange {
            background: $orange;
            border: 1px solid $pink_dark;
        }

        &.is-green {
            background: $ocean-green;
            border: 1px solid darkgreen;
        }

        &.is-invisible {
            background: none;
            border: none;
        }

        &.is-small-input {
            margin: 0 10px;
        }
    }

    .circle-line {
        width: 14px;
        height: 10px;
        -webkit-transform: translateY(-23px) translateX(5px) rotate(
                -45deg
        );

        position: absolute;

        &.is-orange {
            border-bottom: 2px solid $pink_dark;

        }

        &.is-green {
            border-bottom: 2px solid darkgreen;
        }

        &.is-small-input {
            -webkit-transform: translateY(-13px) translateX(5px) rotate(
                    -45deg
            );
        }
    }

    .inOrderCurrency {
        line-height: 30px;
        margin: 0 7px;
        font-size: 10px;
        color: $currency-info-color;
    }

    .link {

        line-height: 30px;
        margin: 0 7px;

        i.material-icons {
            transform: rotate(45deg)
        }
    }
}
