.order-client-invoice {
    overflow-x: initial !important;

    & > .accordion {
        margin-bottom: 40px;
        & > .accordion-header {
            font-weight: 700;
            align-items: center;
            display: flex;
        }
        & > .accordion-content > div { padding: 30px 40px; }
    }



    .overview .is-empty {
        padding: 10px 0;
        font-weight: $font-weight-normal;
    }


    .tables {
        .gray-table {
            max-width: 1170px;
            margin-bottom: 30px;
            border: 1px solid $border-color;
            @include box-with-top-color($bg-color: $background-color);

            .is-info {
                color: $currency-info-color;
                font-weight: $font-weight-normal;
            }

            header, footer {
                min-height: 40px;
                padding: 0 10px;
                font-weight: 600;
                align-items: center;
                display: flex;



                i { font-size: 16px; }
                .fa-plus-circle { font-size: 18px; }
                & > div:first-child {
                    flex-grow: 1;
                    i { margin-right: 10px; }
                }

                button {
                    margin-left: 5px;
                }

            }

            header { border-bottom: 1px solid $border-color; }
            footer {
                margin-right: 74px;
            }


            .head-row { font-weight: 600; }

            .row, .head-row, .is-empty {
                min-height: 35px;
                position: relative;
                padding: 7px 10px;
                border-bottom: 1px solid $border-color;
                align-items: center;
                display: flex;

                & > div:nth-child(1) { flex-grow: 1; padding-right: 12px; }
                & > div:nth-child(2) { width: 150px; padding-right: 12px; }
                & > div:nth-child(3) { width:  150px; text-align: right; }
                & > div:nth-child(4) { width:  150px; text-align: right; }
                & > div:nth-child(5)   { width: 74px; text-align: right; }
            }
            .rows {
                background-color: $gray-lighter;

                &:not(.has-head) {
                    .isEven { background-color: white; }
                    .isOdd  { background-color: $gray-lighter; }
                }

                &.has-head {
                    & > div { background-color: white }

                    & > div.head-row {
                        background-color: $gray-lighter;
                    }
                }
            }

            .btn-visibility {
                width: 30px;
                background-color: transparent;
                position: absolute;
                top: 5px;
                right: 26px;
                border: 0 none;

                &:before {
                    content: '\e8f4';
                    @include font-family-material;
                }

                &.is-loading {
                    &:after {
                        border-color:       $blue-dark;
                        border-top-color:   transparent;
                        border-right-color: transparent;
                    }
                    &:before { content: none; }
                }
            }
            .btn-freeplaces {
                width: 30px;
                background-color: transparent;
                position: absolute;
                top: 4px;
                right: 44px;
                border: 0 none;

                &:before {
                    content: '\e84e';
                    @include font-family-material;
                }
                &.is-loading {
                    &:after {
                        border-color:       $blue-dark;
                        border-top-color:   transparent;
                        border-right-color: transparent;
                    }
                    &:before { content: none; }
                }
                &.has-freeplaces {
                    color: $gray;
                }
            }
            .is-not-visible {
                * { color: $gray; }
                .btn-visibility{
                    color: $blue-dark;
                    &:before { content: '\e8f5'; }
                }
            }

            .rows .is-empty:not(:first-child) { display: none; }


            &:last-child {
                margin-bottom: 0;
                .row {
                    & > div:first-child button { margin-left: 5px; }
                    & > div:nth-child(2) { width: 400px; }
                    & > div:last-child   { width: 350px; }
                }
            }


            &.is-payments {
                /*.row > div:last-child { width: 135px; }*/
            }
        }

        .gray-table.is-payments .row > div:nth-child(1) {
            justify-content: space-between;
            align-items: center;
            display: flex;
        }


        .btn-edit {
            &.in-row {
                display: none;
            }
        }
        .row.is-edit-mode { padding: 0 10px; }
        .row:hover .btn-edit { display: inline-block; }

        button.btn-cancel, button.btn-save {
            width: 30px;
            height: 34px;
            position: absolute;
            top: 0;
            border: 0 none;
            &:hover {
                border: 0 none;
                i { transform: scale(1.3); }
            }
            &.is-loading i { display: none; }
            i { margin-right: 0; }
        }

        button.btn-cancel {
            i { color: $red; }
            right: -30px;
            border-radius: 0;
        }

        button.btn-save {
            background-color: $green;
            right: -60px;
            border-radius: 0 $border-radius $border-radius 0;
            i { color: white; }
        }
    }


    .form {
        .accordion-content {
            .is-flex {
                position: relative;
                padding-bottom: 30px;
                justify-content: space-between;
            }

            .level-left {
                .button {
                    margin-right: 15px;
                }
            }
        }

        .gray-box {
            width: 100%;
            height: 100%;
            @include box-with-top-color($bg-color: $background-color);

            header {
                min-height: 45px;
                padding: 0 10px;
                border-bottom: 1px solid $border-color;
                font-weight: 600;
                align-items: center;
                display: flex;

                i {
                    margin-right: 10px;
                    font-size: 16px;
                }
            }

            main {
                padding: 20px;
                & > div {
                    margin-bottom: 5px;
                    align-items: flex-start;
                    line-height: 30px;
                    justify-content: space-between;
                    display: flex;
                }

                label {
                    width: 150px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    font-weight: 600;
                }

                .field {
                    flex: 1;
                }
            }
            textarea {
                min-width: 0;
                min-height: 50px;
            }
        }
    }


    .is-full-width { width: 100%; }
    .is-date { width: 73px; }
}
.bp-client-invoice {
    .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;

        font-size: 11px;
        color: white;
        text-align: center;
        line-height: 14px;

        background: $accent;
        color: white;
    }

    .row.is-head {
        height: 31px;
        background: $gray;
    }

    .row.is-sub-head {
        height: 31px;
        background: $gray-lighter;
    }

    > .row {
        background: $background-color;
        &:not([class*=' is']):hover {
            background: darken($background-color, 5%);
        }
    }
}

