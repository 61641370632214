/*
    Can be found in Dashboard deadline
*/
.deadline-date {
    height: 25px;
    padding: 2px 5px;
    border-radius: $border-radius;
    line-height: 25px;

    &[class*="is-"] {
        height: 25px;
        margin-top: 2px;
        padding-top: 0;
        color: white;
        i, span { color: white; }
    }

    &.is-red { background-color: $highUrgency; }
    &.is-yellow { background-color: $middleUrgency; }
}
.no-border {
    border:none !important;
}

@media screen and (min-width:1919px){
    .is-hidden-large-screen{
        display:none!important
    }
}
@media screen and (max-width:1919px){
    .is-visible-large-screen{
        display:none!important
    }
}



.bp-deadline {
    position: relative;
    white-space: nowrap;
    align-items: center;
    display: flex;

    &.is-edit-table {
        &::before {
            position: absolute;
            top:21px;
            transform: translateY(-50%);
            left: 0px;
            margin-right: 0;
            margin-left: 8px;
            z-index: 2
        }

        &.is-text {
            &::before {
                top:20px;
            }
        }
    }

    &.is-editable {
        background: white;
        border: 1px solid $border-color;
        border-radius: 0;

        &::before {
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            left: 9px;
            margin-right: 0;
        }

        input {
            height: 28px;
            border: 0;
            background: 0;

            &::placeholder { color: $text-color; }
        }
    }

    &::before {
        content: ' ';
        width: 9px;
        height: 9px;
        margin-right: 7px;
        border-radius: 50%;
        pointer-events: none;
        display: none;
    }

    &.is-red, &.is-yellow {
        &:before { display: inline-block; }
    }

    &.is-red:before    { background: $highUrgency }
    &.is-yellow:before { background: $middleUrgency }

    input { padding-left: 25px; }
    &.is-big input { height: 30px; }
}

.bp-status {
    position: relative;
    white-space: nowrap;
    align-items: center;
    display: flex;

    &::before {
        content: '';
        width: 9px;
        height: 9px;
        border-radius: 50%;
        pointer-events: none;
        display: block;
        position: absolute;
        right: 100%;
        top: 50%;
        margin-top: -5px;
        margin-right: 5px;
    }

    &.is-paid::before { background: $ocean-green; }
    &.is-partially_paid::before { background: $green; }
    &.is-sent::before { background: $pink_dark; }
}



/*
    Can be found in orders > modal > prices
*/
.price-view {
    width: 130px;
    min-height: 12px;
    position: relative;
    padding-right: 8px;
    text-align: right;
    display: inline-block;


    &.has-total {
        width: 147px;
        &:before { left: 78px; }
        div:first-child {
            width: 70px;
            height: 100%;
            float: left;
            text-align: left;
        }
    }

    &.is-close {
        width: 82px;
        padding-right: 0;
        &:before { left: 20px; }
    }

    &.has-icon-plus:before  { content: '+'; }
    &.has-icon-minus:before { content: '-'; }

    &.has-end-icon {
        padding-right: 12px;
        &:before {
            left: auto;
            right: 0;
        }
    }

    &.has-two-end-icons {
        padding-right: 30px;
        &:before {
            left: auto;
            right: 18px;
        }
    }


}



/*
    Mostly used for destination objects
*/
.bordered-object {
    background-color: white;
    margin-right: 6px;
    padding: 0 5px;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    display: inline-block;
    color: $text-color !important;

    &.has-20px-height {
       height: 20px;
    }
}
/*
    Sales Dashboard circles
*/

.circles {
    background: $gray-lighter;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    place-items: center;
    &.is-red {
        background: $pink_dark;
    }
    &.is-yellow {
        background: $yellow;
    }
    &.is-green {
        background: $green;
    }
    .circles-popover {
        display: flex;
    }
}

/*
    Pie chart
*/
.pie-chart {
    width: 20px;
    height: 20px;
    background-image: linear-gradient(to right, transparent 50%, $green 0);
    background-color: $gray-lighter;
    position: relative;
    margin-bottom: -5px;
    margin-right: 4px;
    border-radius: 50%;
    display: inline-grid;
    justify-items: center;

    &.is-red {
        background-image: linear-gradient(to right, transparent 50%, $pink_dark 0);
    }

    &.is-orange {
        background-image: linear-gradient(to right, transparent 50%, $orange 0);
    }

    &:before {
        content: ' ';
        width: 50%;
        height: 100%;
        background-color: inherit;
        position: absolute;
        top: 0;
        left: 50%;
        border-radius: 0 100% 100% 0 / 50%;
        transform-origin: left;
    }
}

@for $i from 0 through 50 {
    .pie-chart[data-p="#{$i}"]:before { transform: rotate(#{round(18 / 5 * $i)}deg); }
}

%pie-chart-invers { background-color: $green; }
%pie-chart-invers-red { background-color: $pink_dark; }
%pie-chart-invers-orange { background-color: $orange; }

@for $i from 51 through 100 {
    .pie-chart[data-p="#{$i}"]:before {
        @extend %pie-chart-invers;
        transform: rotate(#{round(18 / 5 * ($i - 50))}deg);
    }

    .pie-chart.is-red[data-p="#{$i}"]:before {
        @extend %pie-chart-invers-red;
        transform: rotate(#{round(18 / 5 * ($i - 50))}deg);
    }

    .pie-chart.is-orange[data-p="#{$i}"]:before {
        @extend %pie-chart-invers-orange;
        transform: rotate(#{round(18 / 5 * ($i - 50))}deg);
    }
}


/*
    Used in (but not only in) orders
        when you edit/add an item an overlay appears
        preventing any other action until the current one is finished
 */
.is-edit-mode {
    position: relative;
    z-index: 2;
}


.is-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    z-index: 1;
}


.loading-data.is-global {
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 18px;
    font-weight: 600;
    z-index: 20;
}




.level.has-small-margin:not(:last-child),
.panel.has-small-margin:not(:last-child) {
    margin-bottom: .75rem;
}
.has-right-margin { margin-right: 10px; }


.rooms-filter {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 15px;

    > * {
        margin-right: 10px;
    }

    > .label {
        margin-bottom: 0;
    }

    .field {
        margin-bottom: 0;
    }
}


.is-empty {
    padding: 20px;
    text-align: center;
}

.is-full-width {
    width: 100%;
    word-break: break-word;
}

.is-full-height {
    height: 100%;
}

.is-half {
    width: 50%;
    word-break: break-word;
}
