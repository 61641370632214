html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,button,cite,code,del,dfn,em,fieldset,figure,font,img,input,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,select,tbody,textarea,tfoot,thead,tr,th,td{margin:0;padding:0;border:0 none;outline:0 none;vertical-align:baseline;background:transparent;color:$text-color;}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}:focus{outline:0 none}ins{text-decoration:none}del{text-decoration:line-through}table{border-collapse:collapse;border-spacing:0}a,button,input[type="submit"],input[type="reset"]{cursor:pointer}a{text-decoration:none}article,aside,figure,figure img,figcaption,hgroup,footer,header,nav,section,details{display:block;}img{color:transparent;font-size:0;vertical-align:middle;}svg{overflow:hidden;}p,a{word-wrap:break-word;}*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}button,a:active,a:hover{outline:0;}textarea{overflow:auto;}
    /* end of reset */
    /* Golden ratio (0.62 + 0.38) -> for 1200px = 744px + 456px */

::-moz-selection { background:#B3D4FC; text-shadow:none; }
::selection      { background:#B3D4FC; text-shadow:none; }

html { height:100%; }
body { min-height:100%; height: 100%; background-color: $gray-lightest; position:relative; font-size: $font-size; font-weight: $font-weight-normal}
body, input, select, textarea { -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale; font-family: $font-family-primary, 'Helvetica Neue', Helvetica, Arial, sans-serif; }

input, button, a, button i { transition:all 0.2s ease-out; }
button:active, a.button:active { transform:scale(0.95); }
select option[disabled]:first-child { display:none; }
::-webkit-input-placeholder { color: #AAA; }
::-moz-placeholder          { color: #AAA; }
:-ms-input-placeholder      { color: #AAA; }
:-moz-placeholder           { color: #AAA; }

/*
    Overriding Bulma
*/
.nav { z-index: 0; }
.animated { -webkit-animation-duration: 0.5s; animation-duration: 0.5s; }
.input, .input.is-small, .select, .select.is-small, .select select { border-radius:$border-radius; }
.button { border-color:$border-color; font-weight:600; }
.button.is-primary { font-weight:$font-weight-normal; }
.material-icons {
    vertical-align: middle;
    transform: rotate(0.03deg);
    font-size: 17px;

    &.is-smaller { font-size: 16px; }
    &.is-red { color: $pink_dark !important; }
    &.is-orange {
        color: $orange !important;
    }

    &.is-green {
        color: $ocean-green !important;
    }

}

.material-icons, .fa { color: $gray-dark; }
.has-margin-top { margin-top: 1.5rem; }
.icon-spin {animation: spin 1s infinite linear}
.is-pointer {cursor: pointer}
.is-cursor-text { cursor: text }
.is-turqoise-text { color: $accent; }
.is-text-red { color: $pink_dark; }
.is-text-blue { color: $blue; }
.is-text-yellow { color: $orange; }
.is-text-link-color { color: $link-color; }
.is-small-text {font-size: 10px}
.is-tiny-text {font-size: 11px}
.is-relative { position: relative; }
.is-flex-grow { flex-grow: 1; }
.columns.is-fullwidth {width: 100%;}
.py { padding-top: 1.5rem; padding-bottom: 1.5rem; }
.is-blue-dark { color: $blue-dark; }
.is-blue-strong { color: $blue-strong; }
.is-truncated { @include ellipsis() }
.flex-child { min-width: 0 }
.is-text-orange { color: $orange; }
hr.my-sm { margin-top: 5px; margin-bottom:5px }
.mb-0 { margin-bottom: 0 !important}
.br-0 { border-right: 0 !important}
hr.mb-sm { margin-bottom: 15px; }
hr.mt-sm { margin-top: 15px; }
.level.mb-sm { margin-bottom: 1em; }
.mt-0 { margin-top: 0 !important }
.px-xs { padding-left: 5px; padding-right: 5px; }
.level.mb-xs:not(:last-child) { margin-bottom: 5px }
.has-text-active { color: $blue-active }
.font-normal { font-weight: $font-weight-normal }
.font-light { font-weight: 300 !important }

.stars {
    display: flex;
    flex-direction: row;

    .material-icons {
        margin-right: 2px;
        font-size: 12px;
        color: #999;

        &.active {
            color: #333;
        }
    }

    &.stars-sm .material-icons { font-size: 15px }
}
[data-bubble] {
    position: relative;

    &:before {
        content: attr(data-bubble);
        background: $blue-dark;
        color: white;

        padding: 2px 5px;
        font-size: 11px;
        display: inline-block;
        border-radius: 3px;
        line-height: 1;
        margin-right: 5px;
    }
}
.nl2br {white-space: pre-line}
ul.icon-list {
    list-style: none;
    padding-left: 0;

    li {
        display: flex;
        align-items: center;
        text-align: left;

        &:not(:last-of-type) {
            margin-bottom: 4px;
        }

        i {
            margin-right: 10px;
            font-size: 15px;
            margin-top: 1px;
        }
    }
}
.is-line-through {
    text-decoration: line-through
}
.map {
    width: 100%;
    height: 300px;

    &.is-small {
        height: 200px;
    }
}
.is-space-between {
    justify-content: space-between
}
.is-rotated {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
}
.is-transform-transition {
    transition-duration: .25s transform;
}

.is-scrollable-x {
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}
.text-muted {
    color: $gray-dark;
}
.v-tour {
    z-index: 6;

    .v-step__content {
        margin-bottom: 0;
        padding: 15px 0;
        color: white;
        text-align: justify;

        * {
            color: inherit
        }

        i.material-icons {
            vertical-align: top;
            margin-right: 5px;
        }

        ul {
            list-style: disc;
            list-style-position: inside;
        }

        ol {
            list-style: decimal;
            list-style-position: inside;
        }

    }

    .v-step__header {
        margin-bottom: 0;
        color: white;
        font-weight: bold;

        * {
            color: inherit
        }
    }
}

.rounded-btn {
    background: #fff;
    border: 1px solid $border-color;
    width: 20px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    font-size: 11px;
    border-radius: 50%;
}

.svg-inline {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
    width: 1em;

    path {
        fill: currentColor
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
