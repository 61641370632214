.bottom-panel {
    position: fixed;
    bottom: 1px;
    width: 100% !important;
    margin-bottom: 0 !important;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
}
.handle-bar {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    border: 1px solid #cccccc;
    font-size: 16px;
    height: 30px;
    cursor: pointer;
    background-color: #ddd;
}

.toolbar-icon {
    margin-right: 12px;
    color: $blue-lighter
}

.drawer-content {
    background-color: rgb(239, 239, 239);
    transition: 0.5s;
    overflow: auto;
}
